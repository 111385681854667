<template>
  <div class="wrapper">
    <div class="container">
      <div class="section__line">
        <div class="link__top">
          <a class="link__back" @click="$router.go(-1)">
            <span class="link__back--img">
              <img src="../../../assets/img/arrow-back.svg" />
            </span>
            Назад
          </a>
        </div>
      </div>

      <div class="section__line">
        <div class="section__bg section--bank__info section--bank__data">
          <div class="section__title--block section__title--margin">
            <div class="item__column">
              <div class="section__title">Редактирование внешного типового договора</div>

              <div class="item__row item__ac agreement__lang mt-4">
                <div
                  class="agreement__lang__type item__abs mr-2 pointer"
                  @click="lang_type = true"
                  v-bind:class="{ agreement__lang__active: lang_type == true }"
                >
                  RU
                </div>
                <div
                  class="agreement__lang__type item__abs pointer"
                  @click="lang_type = false"
                  v-bind:class="{ agreement__lang__active: lang_type == false }"
                >
                  KZ
                </div>
              </div>
            </div>

            <div class="type__contract">
              <div class="type__contract--img">
                <img src="../../../assets/img/type__contract.svg" />
              </div>
              <div class="type__contract--info">
                <div class="type__contract--type">Тип договора</div>
                <div class="type__contract--name" v-if="document.type">
                  {{ document.type.title }}
                </div>
              </div>
            </div>
          </div>

          <div class="agreement__info--block">
            <div class="agreement__info--item">
              <div class="agreement__info--icon">
                <img src="../../../assets/img/file-icon.svg" />
              </div>
              <div class="agreement__info--right">
                <div class="agreement__info--type">Вид договора</div>
                <div class="agreement__info--title">Внешний</div>
              </div>
            </div>
          </div>

          <form @submit.prevent="createContract()">
            <hr class="line__hr" />

            <div class="agreement__line">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row align-items-center">
                    <div class="col-lg-3">
                      <div class="agreement__item--title" v-if="!lang_type">ФИО</div>
                      <div class="agreement__item--title" v-else>ФИО (на казахском)</div>
                    </div>
                    <div class="col-lg-9">
                      <div class="agreement__item--input">
                        <input
                          type="text"
                          required
                          v-if="!lang_type"
                          v-model="mspd.client_fio"
                          name=""
                          placeholder="ФИО"
                        />
                        <input
                          type="text"
                          required
                          v-else
                          v-model="mspd.client_fio_kz"
                          name=""
                          placeholder="ФИО (на казахском)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="agreement__line">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row align-items-center">
                    <div class="col-lg-3">
                      <div class="agreement__item--title" v-if="!lang_type">
                        Подписант контрагента действует на основании
                      </div>
                      <div class="agreement__item--title" v-else>
                        Подписант контрагента действует на основании (на казахском)
                      </div>
                    </div>
                    <div class="col-lg-9">
                      <div class="agreement__item--input">
                        <input
                          type="text"
                          required
                          v-if="!lang_type"
                          v-model="mspd.client_based_on"
                          placeholder="Подписант контрагента действует на основании"
                          v-bind:class="{
                            input__border: messages && messages.client_based_on,
                          }"
                        />
                        <input
                          type="text"
                          required
                          v-else
                          v-model="mspd.client_based_on_kz"
                          placeholder="Подписант контрагента действует на основании (на казахском)"
                          v-bind:class="{
                            input__border: messages && messages.client_based_on_kz,
                          }"
                        />
                      </div>
                      <div
                        style="padding: 5px 15px; float: left; color: red"
                        v-if="messages && messages.client_based_on && !lang_type"
                      >
                        {{ $t(messages.client_based_on) }}
                      </div>
                      <div
                        style="padding: 5px 15px; float: left; color: red"
                        v-if="messages && messages.client_based_on_kz && lang_type"
                      >
                        {{ $t(messages.client_based_on_kz) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="agreement__line">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row align-items-center">
                    <div class="col-lg-3">
                      <div class="agreement__item--title" v-if="!lang_type">
                        Наименование
                      </div>
                      <div class="agreement__item--title" v-else>
                        Наименование (на казахском)
                      </div>
                    </div>
                    <div class="col-lg-9">
                      <div class="agreement__item--input">
                        <input
                          type="text"
                          required
                          v-if="!lang_type"
                          v-model="mspd.client_bank_name"
                          name=""
                          placeholder="Наименование  "
                          v-bind:class="{
                            input__border: messages && messages.client_bank_name,
                          }"
                        />
                        <input
                          type="text"
                          required
                          v-else
                          v-model="mspd.client_bank_name_kz"
                          name=""
                          placeholder="Наименование    (на казахском)"
                          v-bind:class="{
                            input__border: messages && messages.client_bank_name_kz,
                          }"
                        />
                      </div>
                      <div
                        style="padding: 5px 15px; float: left; color: red"
                        v-if="messages && messages.client_bank_name && !lang_type"
                      >
                        {{ $t(messages.client_bank_name) }}
                      </div>
                      <div
                        style="padding: 5px 15px; float: left; color: red"
                        v-if="messages && messages.client_bank_name && lang_type"
                      >
                        {{ $t(messages.client_bank_name) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr class="line__hr" />

            <div class="row">
              <div class="col-lg-9 offset-lg-3">
                <v-file-input
                  v-model="images"
                  placeholder=""
                  label="Выберите файл"
                  multiple
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>

								<div class="col-lg-4" v-for="file in contract.files" :key="file.id">
                    <div
                      class="mr-2 pointer"
                      @click="downloadContractFile(file.id, file.file_path)"
                    >
                      <i class="mdi mdi-file-document-multiple-outline"></i>
                      <p>{{ file.file_path }}</p>
                    </div>
                  </div>


                <button type="submit" class="form__button">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z"
                      fill="white"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z"
                      fill="white"
                    ></path>
                  </svg>
                  Сохранить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <iframe id="blob-src-test" frameborder="0" width="300px" height="300px"></iframe> -->
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      contract: "",
      lang_type: false,
      document: "",
      mspd: {
        client_signer_in: "",
        client_signer_in_kz: "",
        client_fio: "",
        client_fio_kz: "",
        client_based_on: "Устава",
        client_based_on_kz: "Жарғыдан",
      },
      images: [],
      attachment_1: {
        check_number: "",
        client_for: "",
        bik: "",
      },
      messages: [],
    };
  },
  created() {
    this.connection = new WebSocket("wss://127.0.0.1:13579/");
    this.connection.onopen = () => {
      this.ready = true;
    };
    this.connection.onmessage = (e) => {
      const arr = JSON.parse(e.data);
      const parse__arr = arr;
      this.esp__array = parse__arr;
    };
  },

  methods: {
    getProfile() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "user",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.user = response.data.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    getDocument(id) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "user/document?id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.contract = response.data;
          this.mspd = response.data.fields;
          this.attachment_1 = response.data.attachments[0].fields;
          this.contract.attachment_id = response.data.attachments[0].id;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
		downloadContractFile(id, name) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "document/file?id=" + id,
        responseType: "arraybuffer",
        data: this.contract,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createContract() {
      let contractForm = new FormData();
      let url = "user/documents/smk";

      for (var sm_key in this.mspd) {
        contractForm.append(sm_key, this.mspd[sm_key]);
      }
      contractForm.append("attachment_id", this.contract.attachment_id);
      contractForm.append("document_id", this.contract.id);
			contractForm.append("id", this.contract.id);
      for (var key in this.attachment_1) {
        contractForm.append(
          "attachments[attachment_1][" + key + "]",
          this.attachment_1[key]
        );
      }

      for (var i = 0; i < this.images.length; i++) {
        contractForm.append("documents[]", this.images[i]);
      }
      this.$axios
        .post(this.$API_URL + this.$API_VERSION + url, contractForm, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$toast.open({
            message: "Успешно сохранено",
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          this.$router.go(-1);
        })
        .catch((error) => {
          let errors = error.response.data.errors;
          console.log(errors);

          this.$toast.open({
            message: "Заполните все поля",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          this.messages = [];
          for (let key in error.response.data.errors) {
            this.messages[key] = error.response.data.errors[key].pop();
          }
        });
    },
    getTypes() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "types",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document.types = response.data.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  mounted() {
    this.getDocument(this.$route.params.id);
    this.getTypes();
    this.getProfile();
  },
  watch: {},
};
</script>
